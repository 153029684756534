import React, { useState, useEffect } from 'react';
import { Map } from 'immutable';
import { Switch, Route, useHistory, Redirect } from 'react-router-dom';
import { Renew32, Close24, Tools32 } from '@carbon/icons-react';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { initI18n } from 'utils';
import { ROUTES, User } from '@interfaces';
import { FieldRCAs, PrivateRoute, SuperAdmin, Login as SALogin } from '@applications';
import { ReportContainer, DashboardContainer, AuthProvider, OrganizationProvider, trackEvent } from 'containers';
import { Showcase, Logout } from 'views';
import { useRootReducer } from 'hooks';
import { RoleProtectedRoute } from 'components/RoleProtectedRoute';
import { TreeEditor } from './TreeEditor';
import { Login } from './Login';
import { CreateAccount } from './CreateAccount';
import { NewAnalysisWizard } from './NewAnalysisWizard';
import { Analyses } from './Analyses';
import { Webhooks } from './Webhooks';
import { Admin } from './Admin';
import { Dashboard as LegacyDashboard } from './Dashboard';
import { Templates } from './Templates';
import { GroupsList } from './Groups';
import { ForgotPassword } from './ForgotPassword';
import { ResetPassword } from './ResetPassword';
import { SSOSuccess, SSOFailure } from './SSO';

const isDevelopment = process.env.NODE_ENV === 'development';

const ReconnectingModal = ({ state }) => {
  const [model, setModel] = useState(Map());
  const reconnecting = state.get('reconnecting');
  const slow = model.get('slow');
  const error = model.get('error');

  useEffect(() => {
    if (reconnecting) {
      const idSlow = setTimeout(() => setModel(model.set('slow', true)), 5000);
      const idError = setTimeout(() => setModel(model.set('error', true).delete('slow')), 10000);

      return () => {
        clearTimeout(idSlow);
        clearTimeout(idError);
      };
    } else {
      setModel(Map());
    }
  }, [reconnecting, model, setModel]);

  return (
    <div
      className={classNames(
        'fixed top-0 left-0 h-100 w-100 z-999 bg-white-80 justify-center items-center flex-column',
        {
          dn: !reconnecting,
          flex: reconnecting,
        },
      )}
    >
      <div
        className={classNames('flex-column justify-center items-center fadeIn', {
          flex: !error,
          dn: error,
        })}
      >
        <Renew32 className={'spin mb2 fill--blue blue'} />
        <div className={'f2 mb2'}>Updating Application</div>
        <div className={classNames('f3 gray fadeIn', { dn: !slow })}>Just a few more seconds.</div>
      </div>
      <div
        className={classNames('flex-column justify-center items-center fadeIn', {
          flex: error,
          dn: !error,
        })}
      >
        <Tools32 className={'mb2 fill--blue blue'} />
        <div className={'f2 mb2'}>Refresh to Complete Update</div>
        <div className={'f3 gray'}>Your data is there and will come right back.</div>
      </div>
    </div>
  );
};

const Toast = ({ durationDefault, state, dispatch }) => {
  const toast = state.get('toast');
  const message = state.getIn(['toast', 'message']);
  const style = state.getIn(['toast', 'style'], 'DEFAULT');
  const action = state.getIn(['toast', 'action', 'message']);
  const onClick = state.getIn(['toast', 'action', 'onClick'], () => null);

  const durationMillis = state.getIn(['toast', 'durationMillis'], durationDefault);

  useEffect(() => {
    if (toast) {
      const id = setTimeout(() => {
        dispatch(Map({ type: 'HIDE_TOAST' }));
      }, durationMillis);

      return () => {
        clearInterval(id);
      };
    }
  }, [dispatch, durationMillis, toast]);

  return (
    <div
      className={classNames('z-9999 right-1 w6 fixed bl bw2', {
        'bg-washed-yellow b--yellow': style === 'WARNING',
        'bg-washed-red b--red': style === 'ERROR',
        'bg-washed-green b--green': style === 'SUCCESS',
        'bg-washed-blue b--blue': style === 'DEFAULT',
        dn: !message,
      })}
      style={{ top: '3.5rem' }}
    >
      <div className={'relative flex'}>
        <div className={'pa3 flex-auto'}>
          {message}
          <div
            className={classNames('mt1 underline blue pointer', {
              dn: !action,
            })}
            onClick={() => {
              onClick();
              dispatch(Map({ type: 'HIDE_TOAST' }));
            }}
          >
            {action}
          </div>
        </div>
        <div
          className={'pa2 dim flex justify-center items-center pointer'}
          onClick={() => dispatch(Map({ type: 'HIDE_TOAST' }))}
        >
          <Close24 />
        </div>
      </div>
    </div>
  );
};

export const App = () => {
  const { state, dispatch } = useRootReducer();

  const url = state.get ? state.get('url') : state.url;
  const history = useHistory();

  useEffect(() => {
    return history.listen((location, action) => {
      trackEvent('Load View', { View: location.pathname });

      // When a user navigates back to the RCA list, clear any previous search state
      if (location.pathname.includes(ROUTES.RCA_LIST) && action === 'POP') {
        dispatch(Map({ type: 'CLEAR_SEARCH' }));
      } else if (location.pathname === '/v2') {
        window.location.href = '/v2/';
      } else if (location.pathname === ROUTES.ORGANIZATION_SETTINGS) {
        window.location.href = `/#${ROUTES.SETTINGS_USERS}`;
      } else if (location.pathname === ROUTES.ORGANIZATION_SETTINGS_WEBHOOKS) {
        window.location.href = `/#${ROUTES.SETTINGS_INTEGRATIONS}`;
      } else if (location.pathname === ROUTES.ORGANIZATION_SETTINGS_GROUPS) {
        window.location.href = `/#${ROUTES.SETTINGS_GROUPS}`;
      } else {
        dispatch(Map({ type: 'SET_URL', url: location.pathname }));
      }

      console.trace('SET_URL', { location, action });
    });
  }, [history, dispatch]);

  useEffect(() => {
    if (url !== history.location.pathname) {
      history.push(url);
    }
  }, [url, history]);

  const sUsername = state.get('username');

  const { username, admin, superadmin, paid, fullName, complimentary, orgFeatures }: User = state
    .getIn(['users', sUsername], Map())
    .toJS();
  const token = state.get('token');

  useEffect(() => {
    if (orgFeatures?.i18n) {
      initI18n(strings => {
        dispatch(Map({ type: 'SET_I18N_STRINGS', strings: strings }));
      });
    }
  }, [orgFeatures?.i18n]);

  // const roles = state.getIn(['users', user.username, 'roles'], List()).toJS();
  const initialAuthState = React.useMemo(
    () => ({
      username,
      name: fullName,
      roles: {
        admin,
        superadmin,
        paid,
        complimentary,
      },
      token,
      isAdmin: admin,
      orgFeatures: orgFeatures,
    }),
    [username, fullName, token, admin, complimentary, paid, superadmin, orgFeatures],
  );

  return (
    <AuthProvider initialValue={initialAuthState} onLogout={() => dispatch(Map({ type: 'SIGN_OUT' }))}>
      <OrganizationProvider>
        <div className="h-100 w-100 bg-white application">
          {/* @ts-ignore */}
          <ReactTooltip />
          <Toast state={state} dispatch={dispatch} durationDefault={5000} />
          <div className={'right-0 top-2 fixed bg-transparent'} style={{ zIndex: 2 }}>
            <br />
            <div className={'i18nSwitcher'} />
          </div>
          <ReconnectingModal state={state} />
          <Switch>
            <Route path={ROUTES.CREATE_ACCOUNT}>
              <CreateAccount />
            </Route>
            <Route path={ROUTES.FORGOT_PASSWORD}>
              <ForgotPassword state={state} dispatch={dispatch} />
            </Route>
            <Route path={`${ROUTES.RESET_PASSWORD}/:token`}>
              {/* @ts-ignore */}
              <ResetPassword state={state} dispatch={dispatch} />
            </Route>
            <Route path="/legacy-dashboard" exact>
              <LegacyDashboard state={state} dispatch={dispatch} />
            </Route>
            <Route path={ROUTES.DASHBOARD} component={DashboardContainer} />
            <Route path={ROUTES.RCA_LIST} exact>
              <Analyses state={state} dispatch={dispatch} />
            </Route>
            <Route path={ROUTES.NEW_ANALYSIS_WIZARD}>
              <NewAnalysisWizard state={state} dispatch={dispatch} />
            </Route>

            <Route path={ROUTES.SETTINGS_USERS} exact>
              <Admin state={state} dispatch={dispatch} />
            </Route>

            <Route path={ROUTES.SETTINGS_GROUPS}>
              <GroupsList state={state} dispatch={dispatch} />
            </Route>

            <Route path={ROUTES.SETTINGS_INTEGRATIONS}>
              <Webhooks state={state} dispatch={dispatch} />
            </Route>
            <Route path={ROUTES.TEMPLATES} exact>
              <Templates state={state} dispatch={dispatch} />
            </Route>
            <Route path={`${ROUTES.TREE}/:uuid`}>
              <TreeEditor state={state} dispatch={dispatch} />
            </Route>
            <Route path={`${ROUTES.REPORT}/:treeUUID/:signature?`} exact>
              {/* @ts-ignore */}
              <ReportContainer state={state} dispatch={dispatch} />
            </Route>
            <Route path={`${ROUTES.SSO}/failure`} exact>
              {/* NB: Order matters, MUST be before the /:usernameEncoded/:token route */}
              {/* @ts-ignore */}
              <SSOFailure state={state} dispatch={dispatch} />
            </Route>
            <Route path={`${ROUTES.SSO}/:usernameEncoded/:token`}>
              <SSOSuccess state={state} dispatch={dispatch} />
            </Route>
            <RoleProtectedRoute debug role="ADMIN" path="/field-rca/:orgUUID?">
              <FieldRCAs />
            </RoleProtectedRoute>
            <Route path={ROUTES.SUPERADMIN_LOGIN}>
              <SALogin state={state} dispatch={dispatch} />
            </Route>
            {isDevelopment ? (
              <Route path={ROUTES.SHOWCASE} exact>
                <Showcase />
              </Route>
            ) : null}
            <PrivateRoute state={state} path="/sa">
              <SuperAdmin state={state} dispatch={dispatch} />
            </PrivateRoute>
            <Route path={ROUTES.LOGOUT}>
              <Logout dispatch={dispatch} />
            </Route>
            <Redirect from={ROUTES.ORGANIZATION_SETTINGS} to={ROUTES.SETTINGS_USERS} exact />
            <Redirect from={ROUTES.ORGANIZATION_SETTINGS_GROUPS} to={ROUTES.SETTINGS_GROUPS} exact />
            <Redirect from={ROUTES.ORGANIZATION_SETTINGS_WEBHOOKS} to={ROUTES.SETTINGS_INTEGRATIONS} exact />
            <Route path="*">
              <Login state={state} dispatch={dispatch} />
            </Route>
          </Switch>
        </div>
      </OrganizationProvider>
    </AuthProvider>
  );
};
