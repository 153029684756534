export enum UserRole {
  'ADMIN' = 'ADMIN',
  'SUPER_ADMIN' = 'SUPER_ADMIN',
  'PAID' = 'PAID',
  'COMPLIMENTARY' = 'COMPLIMENTARY',
}

export type UserRoleProperties = {
  superadmin: boolean;
  invited: boolean;
  paid: boolean;
  admin: boolean;
  complimentary: boolean;
};

export type FeatureFlags = {
  templates: boolean;
  i18n: boolean;
  taskMetadata: boolean;
};

export type User = UserRoleProperties & {
  expired: boolean;
  companyName: string;
  phone: string;
  organizationId: number;
  licenses: number;
  username: string;
  fullName: string;
  jobTitle: string;
  id: number;
  createdAt: string;
  email: string;
  orgFeatures: FeatureFlags;
  /** @deprecated use superadmin, paid, admin, or complementary keys instead */
  roles?: UserRole[];
};

export type OrganizationMember = Omit<User, 'id'>;

export type Group = {
  groupUuid: string;
  name: string;
  mbrcnt: number;
  admins?: string[] | GroupMember[];
  members?: string[] | GroupMember[];
};

export type GroupMember = Pick<User, 'username' | 'fullName'> & { id: string };

export type ReportAnalysisMember = Pick<User, 'fullName' | 'jobTitle' | 'username'>;

export enum AnalysisMethodology {
  PROACT = 'PROACT',
  FIVE_WHYS = '5WHYS',
  FISHBONE = 'FISHBONE',
}

export const MethodologyDisplay = {
  [AnalysisMethodology.PROACT]: 'PROACT',
  [AnalysisMethodology.FIVE_WHYS]: "5 Why's",
  [AnalysisMethodology.FISHBONE]: 'Fishbone',
} as const;

export type ValidDisplayMethodology = typeof MethodologyDisplay[keyof typeof MethodologyDisplay];

export enum AnalysisStatus {
  COMPLETE = 'COMPLETE',
  PENDING = 'PENDING',
  IN_PROCESS = 'IN_PROCESS',
  REVIEW = 'REVIEW',
  ARCHIVE = 'ARCHIVE',
}

export const AnalysisStatusDisplay: Record<AnalysisStatus, string> = {
  [AnalysisStatus.PENDING]: 'Pending',
  [AnalysisStatus.IN_PROCESS]: 'In Process',
  [AnalysisStatus.REVIEW]: 'Review',
  [AnalysisStatus.COMPLETE]: 'Complete',
  [AnalysisStatus.ARCHIVE]: 'Archive',
};

export type ActiveStatus = AnalysisStatus.IN_PROCESS | AnalysisStatus.PENDING | AnalysisStatus.REVIEW;

export type AnalysisStatusValues = keyof typeof AnalysisStatus;

export type PROACTNodeTypes =
  | 'event'
  | 'failureMode'
  | 'failureEvent'
  | 'hypothesis'
  | 'notTrue'
  | 'contributingFactor'
  | 'physical'
  | 'human'
  | 'systemic'
  | 'correctiveAction';

export type FishboneNodeTypes =
  | 'event'
  | 'failureMode'
  | 'failureEvent'
  | 'manpower'
  | 'generic' // labeled as 'note' in tree editor
  | 'measurement'
  | 'method'
  | 'materials'
  | 'machinery';

export type FiveWhysNodeTypes = 'why1' | 'why2' | 'why3' | 'why4' | 'why5' | 'why6';

export type TreeNode<T> = {
  id: string;
  oid: string;
  text: string;
  children: TreeNode<T>[];
  type: T;
  hidden?: boolean;
  possible?: boolean;
  verified?: boolean;
};

export type ReportTree = {
  canView: boolean;
  createdAt: number;
  customValues: [];
  customerImpact: number | null;
  deletedAt: number | null;
  description: string;
  edges: Record<string, unknown>;
  elements: [];
  environmentalImpact: number | null;
  facility?: DeepHierarchyObject;
  equipment?: DeepHierarchyObject;
  methodology: AnalysisMethodology;
  findings: string | null;
  frequency: number;
  injuries: number | null;
  isFavorite: boolean;
  isTemplate: boolean;
  laborCost: string;
  productionCost: string;
  propertyCost: string;
  safetyImpact: number | null;
  snapshotAt: number;
  status: AnalysisStatus;
  title: string;
  treeUuid: string;
};

export type PROACTTree = ReportTree & {
  methodology: AnalysisMethodology.PROACT;
  elements: TreeNode<PROACTNodeTypes>[] | [];
};

export type FishboneTree = ReportTree & {
  methodology: AnalysisMethodology.FISHBONE;
  elements: TreeNode<FishboneNodeTypes>[] | [];
};

export type FiveWhysTree = ReportTree & {
  methodology: AnalysisMethodology.FIVE_WHYS;
  elements: TreeNode<FiveWhysNodeTypes>[] | [];
};
export type Tree = PROACTTree | FishboneTree | FiveWhysTree;

export type Node = TreeNode<FiveWhysNodeTypes> | TreeNode<FishboneNodeTypes> | TreeNode<PROACTNodeTypes>;

type Note = {
  createdAt: number;
  createdBy: string;
  nodeUuid: string;
  noteType: string; // COMMENT, FINDING, VERIFICATION
  noteUuid: string;
  text: string;
};

type File = {
  content_type: string;
  created_at: number;
  file_uuid: string;
  filename: string;
  node_uuid: string;
  size: number;
  uploaded_by: string;
  url: string;
};

export enum TaskType {
  VERIFICATION = 'VERIFICATION',
  CORRECTIVE_ACTION = 'CORRECTIVE_ACTION',
}

export type TaskTypes = keyof typeof TaskType;

export type Task = {
  completedAt?: number | null;
  completedBy?: string | null;
  createdAt: number;
  createdBy: string;
  deadline: number;
  description: string;
  doers: string[];
  Hypothesis: string;
  nodeText?: string;
  nodeUuid?: string;
  taskType: TaskTypes | string;
  taskUuid: string;
  title: string;
  treeTitle: string;
  treeUuid: string;
};

type TimelineEvent = {
  eventUuid: string;
  text: string;
  time: number;
};

export type Report = {
  files: File[];
  members: ReportAnalysisMember[];
  notes: Note[];
  tasks: Task[];
  timelineEvents: TimelineEvent[];
  tree: Tree;
};

export type RCA = {
  // canView: boolean;
  // createdAt: number;
  // createdBy: string;
  // createdByName: string;
  // customerImpact: string;
  // deletedAt: number;
  // description?: string;
  // environmentalImpact: string;
  // expectedCompletedAt?: number;
  // findings: string;
  // frequency: number;
  // id: number;
  // injuries?: string;
  // isFavorite: boolean;
  // isTemplate: boolean;
  // laborCost: string;
  // members: string[] | [];
  // methodology: AnalysisMethodology;
  // productionCost: string;
  // propertyCost: string;
  // safetyImpact: string;
  // startAt?: number;
  // status: AnalysisStatus;
  // title?: string;
  // treeUuid: string;
  // tasksOpen: number;
  // tasksCompleted: number;
  // id: number;
  // Sequential analsis number for Organization
  number: number;
  treeUuid: string;
  createdAt: number;
  updatedAt: number;
  deletedAt: number | null;
  canView: boolean;
  methodology: AnalysisMethodology;
  title: string;
  description: string;
  status: AnalysisStatus;
  isFavorite: boolean;
  isTemplate: boolean;
  findings: string | null;
  createdBy: string;
  createdByName: string;
  owner: string;
  ownerName: string;
  groupUuid: string;
  groupName: string;
  organizationId: number;
  injuries: string;
  published: boolean;
  safetyImpact: string;
  environmentalImpact: string;
  customerImpact: string;
  productionCost: string;
  propertyCost: string;
  laborCost: string;
  frequency: number;
  startAt: number;
  eventAt: number;
  expectedAt: number;
  completedAt: number;
  eventNode: string;
  members: OrganizationMember[];
  tasksOpen: number;
  tasksCompleted: number;
  customFields: CustomFieldMap;
  customValues: CustomValueMap;
  facility?: DeepHierarchyObject;
  equipment?: DeepHierarchyObject;
};

export type CustomField = {
  uuid: string;
  index: number;
  type: keyof typeof CustomFieldType;
  label: string;
  size: 255;
};

// CustomValues are the other half of CustomFields–they are attached to RCAs and must meet the criteria (size, type) specified by their corresponding CustomField to be attached successfully.
export type CustomValue = string;

export type CustomFieldMap = Record<string, CustomField>;

export type CustomValueMap = Record<string, CustomValue>;

export enum CustomFieldType {
  string = 'string',
}

export const isActiveRCA = (rca: RCA): rca is RCA => {
  return rca.status !== AnalysisStatus.ARCHIVE;
};

export type DeepHierarchyObject = {
  namePath: Array<string>;
  uuidPath: Array<string>;
  path: Array<string>;
};
