import React, { useEffect } from 'react';
import cx from 'classnames';
import { Map, List, Set } from 'immutable';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { Close20, Copy16, Renew32 } from '@carbon/icons-react';

import { Page } from './Page';
import { initDispatchedWS } from './Sync';
import { GroupMembers } from './GroupMembers';
import { AddGroupMember } from './AddGroupMember';

import './groups.css';

const UpdateGroupPopover = ({ state, dispatch, groupUuid, groupName }) => {
  const [name, setName] = React.useState(groupName);
  const [isVisible, setIsVisible] = React.useState(false);
  const ref = React.createRef();

  const detectClicksRef = useDetectClickOutside({
    onTriggered: () => {
      return setIsVisible(false);
    },
  });

  React.useEffect(() => {
    if (isVisible && ref.current) {
      ref.current.focus();
    }

    return null;
  }, [isVisible, ref]);

  const handleUpdate = () => {
    dispatch(
      Map({
        type: 'UPDATE_GROUP',
        groupUuid: groupUuid,
        groupName: name,
      }),
    );

    setIsVisible(false);
  };
  const invalidName = name === null || name === '' || name === groupName;
  return (
    <div ref={detectClicksRef} style={{ marginLeft: '16px' }}>
      <button className={cx('pa2 flex items-center justify-start', {})} onClick={() => setIsVisible(state => !state)}>
        <span className="i18n">Rename</span>
      </button>
      <div className={cx('rename-popover', { dn: !isVisible })}>
        <form onSubmit={e => e.preventDefault()}>
          <div className="popover-fields">
            <input
              ref={ref}
              className="w-75 bw1 pa2 bb bg-black-05 b--black-20"
              onChange={e => setName(e.target.value)}
              value={name}
            />
            <button
              onClick={handleUpdate}
              type="submit"
              disabled={invalidName}
              className={cx('w-25 white bg-black pa2 ph3', {
                'o-40': invalidName,
              })}
            >
              OK
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const DeleteGroupButton = ({ handleDelete, groupName }) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = React.useState(false);
  const handleShowConfirmation = () => setShowDeleteConfirmation(true);
  const handleCancelConfirmation = () => setShowDeleteConfirmation(false);
  const handleDeleteAndHideConfirmation = () => {
    handleDelete();
    handleCancelConfirmation();
  };

  /*
    set up a handler for the initial delete button
    that shows the confirmation if it's not already visible
    and closes it otherwise
  */
  const handleToggleConfirmation = () => {
    if (showDeleteConfirmation) {
      return handleCancelConfirmation();
    }

    return handleShowConfirmation();
  };

  return (
    <div className="confirmation-container">
      <button className="flex items-center justify-center ml-auto mr-auto" onClick={handleToggleConfirmation}>
        <Close20 />
      </button>
      <div
        className={cx(
          { dn: !showDeleteConfirmation },
          {
            'flex items-center justify-start inline-confirmation flex-column': showDeleteConfirmation,
          },
        )}
      >
        <span className="inline-confirmation-label">Are you sure?</span>
        <p>This group will be removed from all associated RCAs</p>
        <div className="inline-confirmation-actions flex items-center justify-center">
          <button className="inline-confirmation-actions-item red fw6" onClick={handleDeleteAndHideConfirmation}>
            Yes
          </button>
          <span className="inline-confirmation-actions-item separator o-65">/</span>
          <button className="inline-confirmation-actions-item black-60" onClick={handleCancelConfirmation}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

const GroupRow = ({
  user,
  users,
  data,
  shouldFetchMembers,
  onDelete,
  isOrgAdmin,
  onToggleAdminStatus,
  onRemoveMember,
  state,
  dispatch,
}) => {
  /* eslint-disable react-hooks/exhaustive-deps */
  React.useEffect(() => {
    if (!shouldFetchMembers) {
      return;
    }

    dispatch(
      Map({
        type: 'GET_GROUP_MEMBERS',
        groupUuid: data.get('groupUuid'),
      }),
    );
  }, [shouldFetchMembers]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const handleAddMember = ({ username, admin }) =>
    dispatch(
      Map({
        type: 'ADD_USER_TO_GROUP',
        groupUuid: data.get('groupUuid'),
        admin,
        username,
      }),
    );
  const memberIds = data.get('members', List());
  const handleRemoveMember = username => {
    return onRemoveMember({ username, groupUuid: data.get('groupUuid') });
  };
  const isGroupAdmin = data.get('admins').includes(user.get('username'));
  const members = data.get('member-objects', List());
  const handleDeleteGroup = () => onDelete(data.get('groupUuid'));

  return (
    <>
      <tr className="group-row" key={data.get('groupUuid')}>
        <td className="pv2 ph3 ba b--black-70">{data.get('name')}</td>
        <td className="pv2 ph3 ba b--black-70">
          <div className="flex items-stretch justify-start">
            <GroupMembers
              members={members}
              admins={data.get('admins')}
              groupUuid={data.get('groupUuid')}
              currentUser={state.getIn(['organization', 'user', 'username'])}
              currentUserIsAdmin={isGroupAdmin || isOrgAdmin}
              onToggleAdminStatus={onToggleAdminStatus}
              onRemoveMember={handleRemoveMember}
            />
            {(isOrgAdmin || isGroupAdmin) && (
              <AddGroupMember onSelectMember={handleAddMember} memberIds={memberIds} orgMembers={users} />
            )}
            {isOrgAdmin && (
              <UpdateGroupPopover
                state={state}
                dispatch={dispatch}
                groupUuid={data.get('groupUuid')}
                groupName={data.get('name')}
              />
            )}
            <button
              onClick={() =>
                dispatch({
                  type: 'COPY_TO_CLIPBOARD',
                  value: data.get('groupUuid'),
                  message: 'Group UUID added to your clipboard',
                })
              }
              className="ml2 ph2"
            >
              <span>Copy UUID</span>
            </button>
          </div>
        </td>
        <td className="pv2 ph3 ba b--black-70">
          {isOrgAdmin && <DeleteGroupButton groupName={data.get('name')} handleDelete={handleDeleteGroup} />}
        </td>
      </tr>
    </>
  );
};

const CreateGroupPopover = ({ state, dispatch }) => {
  const [name, setName] = React.useState('');
  const [isVisible, setIsVisible] = React.useState(false);
  const ref = React.createRef();

  const detectClicksRef = useDetectClickOutside({
    onTriggered: () => {
      return setIsVisible(false);
    },
    triggerKeys: ['Escape', 'Esc'], // 'Esc' is for Edge/IE
  });

  React.useEffect(() => {
    if (isVisible && ref.current) {
      ref.current.focus();
    }

    return null;
  }, [isVisible, ref]);

  const handleCreate = () => {
    dispatch(
      Map({
        type: 'ADD_GROUP',
        groupName: name,
        adminId: state.getIn(['organization', 'user', 'username']),
      }),
    );

    setIsVisible(false);
    setName('');
  };
  return (
    <div className="relative w-25" ref={detectClicksRef}>
      <button
        onClick={() => setIsVisible(state => !state)}
        className="flex items-center justify-between trans w-100 mb3 white b--blue bg-blue pa2"
      >
        <div className="i18n pr3">Add Group</div>
        <svg
          focusable="false"
          preserveAspectRatio="xMidYMid meet"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          width="24"
          height="24"
          viewBox="0 0 32 32"
          aria-hidden="true"
          className="white fill--white"
        >
          <path d="M17 15L17 8 15 8 15 15 8 15 8 17 15 17 15 24 17 24 17 17 24 17 24 15z"></path>
        </svg>
      </button>
      <div
        className={cx(
          'create-group-popover ba bw1 bg-white b--near-black flex-column justify-start items-center b--black absolute',
          { dn: !isVisible },
        )}
      >
        <form
          onSubmit={e => {
            e.preventDefault();
            handleCreate();
          }}
        >
          <div className="flex flex-column w-100 b popover-header">
            <p className="popover-title">New Group</p>
            <p className="popover-subtitle">What would you like to call it?</p>
            <button onClick={() => setIsVisible(false)} className="dismiss-popover">
              <Close20 />
            </button>
          </div>
          <div className="popover-fields">
            <input
              ref={ref}
              className="w-75 bw1 pa2 bb bg-black-05 b--black-20"
              onChange={e => setName(e.target.value)}
              value={name}
              placeholder="Group name"
            />
            <button
              onClick={handleCreate}
              type="submit"
              disabled={name === null || name === ''}
              className={cx('w-25 white bg-black pa2 ph3', {
                'o-40': name === null || name === '',
              })}
            >
              OK
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export const GroupsList = ({ state, dispatch }) => {
  const [isInitializing, setIsInitializing] = React.useState(true);
  const socket = state.get('ws');
  const user = state.getIn(['organization', 'user'], Map());
  const username = state.get('username');

  const isAdmin =
    state.get('users', Map()).filter(u => u.get('roles', Set()).has('ADMIN') && u.get('username') === username).size >
    0;
  const groupsList = state.get('groups', List());
  /* eslint-disable react-hooks/exhaustive-deps */
  const handleDelete = React.useCallback(groupUuid => {
    dispatch(Map({ type: 'DELETE_GROUP', groupUuid }));
  }, []);
  const handleToggleAdminStatus = React.useCallback(({ username, groupUuid, isPromotion }) => {
    dispatch(
      Map({
        type: 'SET_GROUP_ADMIN',
        username,
        groupUuid,
        admin: isPromotion,
      }),
    );
  }, []);
  const handleRemoveMember = React.useCallback(({ username, groupUuid }) => {
    dispatch(Map({ type: 'REMOVE_USER_FROM_GROUP', username, groupUuid }));
  }, []);

  React.useEffect(() => {
    if (socket) {
      // view is rendering for the first time
      // ...fetch groups and flip initialization flag
      if (isInitializing) {
        // make sure we don't have any holes in store
        dispatch(Map({ type: 'GET_ORGANIZATION' }));
        dispatch(Map({ type: 'LIST_GROUPS', username: user.get('username') }));
        return setIsInitializing(false);
      }
    } else {
      initDispatchedWS(null, dispatch, err => {
        if (err) {
          return dispatch(Map({ type: 'SET_URL', url: '/' }));
        }

        if (isInitializing) {
          // make sure we don't have any holes in store
          dispatch(Map({ type: 'GET_ORGANIZATION' }));
          dispatch(
            Map({
              type: 'LIST_GROUPS',
              username: user.get('username'),
            }),
          );
          return setIsInitializing(false);
        }
      });
    }
  }, [socket, isInitializing, user]);

  /* eslint-enable react-hooks/exhaustive-deps */

  const computedStyles = cx({
    'w-100 flex justify-center': true,
  });

  const isOrgAdmin = state.getIn(['users', user.get('username'), 'roles'], List()).includes('ADMIN');

  return (
    <Page
      title={state.getIn(['organization', 'companyName'])}
      className={computedStyles}
      state={state}
      dispatch={dispatch}
    >
      <div className="content_table">
        <div className="w-100 center">
          <div className="cf">
            <div className="fl w-100">
              <div className="bg-white">
                {isAdmin ? <CreateGroupPopover state={state} dispatch={dispatch} /> : ''}
                {!isInitializing ? (
                  <table className="collapse w-100 ba br2 b--black-10 pv2 ph3">
                    <thead>
                      <tr className="striped--light-gray">
                        <th className="bg-light-gray pv2 ph3 tl fw6 mt1 ttc ba b--black-70">
                          <span className="i18n">Name</span>
                        </th>

                        <th width="35%" className="bg-light-gray pv2 ph3 tl fw6 mt1 ttc ba b--black-70">
                          &nbsp;
                        </th>

                        <th width="50" className="bg-light-gray pv2 ph3 tl fw6 mt1 ttc ba b--black-70" align="center">
                          &nbsp;
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {groupsList.map(group => (
                        <GroupRow
                          shouldFetchMembers={state.get('groups').size > 0}
                          key={group.get('groupUuid')}
                          onDelete={handleDelete}
                          onRemoveMember={handleRemoveMember}
                          onToggleAdminStatus={handleToggleAdminStatus}
                          isOrgAdmin={isOrgAdmin}
                          user={user}
                          users={state.get('users')}
                          data={group}
                          state={state}
                          dispatch={dispatch}
                        />
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className="w-100 flex items-center black">
                    <div className="spin blue">
                      <Renew32 />
                    </div>
                    <div className="ml3 f3">Loading Groups</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};
