import React from 'react';
import { usePopper } from 'react-popper';
import { debounce } from 'lodash';
import cx from 'classnames';
import { useDetectClickOutside } from 'react-detect-click-outside';
import maxSize from 'popper-max-size-modifier';
import { Avatar } from './Nav';

const applyMaxSize = {
  name: 'applyMaxSize',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['maxSize'],
  fn({ state }) {
    const { height, width } = state.modifiersData.maxSize;

    state.styles.popper = {
      ...state.styles.popper,
      zIndex: 2,
      maxHeight: `${height}px`,
      maxWidth: `${width}px`,
    };
  },
};

const SearchResults = ({ results, onSelectMember }) => {
  return (
    <ul>
      {results &&
        results.map &&
        results.valueSeq().map(m => {
          const handleAddMember = () => onSelectMember({ username: m.get('username'), admin: false });
          return (
            <li key={m.get('username')}>
              <button onClick={handleAddMember} className="flex items-center bg-transparent justify-start w-100">
                <div className="pr1">
                  <Avatar height={40} username={m.get('username')} />
                </div>
                <div className="w-70 flex items-start justify-start flex-column">
                  <span className="fw6 pb1" style={{ fontSize: '0.8rem' }}>
                    {m.get('fullName')}
                  </span>
                  <span style={{ fontSize: '0.85rem' }}>{m.get('username')}</span>
                </div>
              </button>
            </li>
          );
        })}
    </ul>
  );
};

export const AddGroupMember = ({
  memberIds,
  orgMembers,
  // groupUuid,
  onSelectMember,
}) => {
  const [isVisible, setIsVisible] = React.useState(false);
  const [results, setResults] = React.useState([]);
  const availableMembers = React.useMemo(() => {
    const available = orgMembers.filter(m => {
      if (memberIds && memberIds.includes) {
        return !memberIds.includes(m.get('username'));
      }

      return false;
    });
    const sortedMembers = available
      .filter(u => !!u.get('fullName') && u.get('fullName') !== '')
      .sort((a, b) => {
        const aName = a.toJS().fullName.toLowerCase();
        const bName = b.toJS().fullName.toLowerCase();

        if (aName < bName) {
          return -1;
        }

        if (aName > bName) {
          return 1;
        }

        return 0;
      });

    setResults(sortedMembers);

    return sortedMembers;
  }, [memberIds, orgMembers]);
  const [referenceElement, setReferenceElement] = React.useState(null);
  const [popperElement, setPopperElement] = React.useState(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      maxSize,
      {
        name: 'flip',
        options: { flipVariations: false, fallbackPlacements: [] },
      },
      applyMaxSize,
    ],
    placement: 'bottom-start',
  });

  const handleSearch = React.useCallback(
    e => {
      const results = availableMembers.filter((v, k) => {
        const usernameMatch = k.includes(e.target.value);
        const nameMatch = v.get('fullName').toLowerCase().includes(e.target.value.toLowerCase());

        return usernameMatch || nameMatch;
      });

      return setResults(results);
    },
    [availableMembers],
  );

  const debouncedHandleSearch = React.useMemo(() => debounce(handleSearch, 300), [handleSearch]);

  /* eslint-disable react-hooks/exhaustive-deps */
  // cancel our debounced search handler if we're unmounted
  React.useEffect(() => {
    return () => {
      debouncedHandleSearch.cancel();
    };
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  const handleOnClick = React.useCallback(() => {
    setIsVisible(state => !state);
  }, []);

  const detectClicksRef = useDetectClickOutside({
    onTriggered: () => setIsVisible(false),
  });

  return (
    <div ref={detectClicksRef} style={{ marginLeft: '16px' }}>
      <button
        className={cx('pa2 flex items-center justify-start', {})}
        onClick={handleOnClick}
        ref={setReferenceElement}
      >
        <span className="i18n">Add Member</span>
      </button>
      <div ref={setPopperElement} style={styles.popper} {...attributes.popper}>
        {isVisible && (
          <div className="members-popover add-member dark-bg">
            <div className="flex members-popover-header">
              <input
                onChange={debouncedHandleSearch}
                className="pa2 bg-white-05 w-100"
                type="search"
                placeholder="Email or name"
                autoFocus
                tabIndex="1"
              />
            </div>
            {results.size > 0 ? (
              <SearchResults onSelectMember={onSelectMember} results={results} />
            ) : (
              <div className="empty-state">
                <span className="f6 white">No members found.</span>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
